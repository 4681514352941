<template>
  <fki-sub-nav
    is-active
    :color="page.color"
    :items="items"
  />
  <fki-container
    fixed
    top="196px"
    left="0"
    width="100%"
    class="fki-contact-page"
  >
    <div
      v-if="currentFacility"
      class="fki-contact-wrapper"
    >
      <fki-contact-facility
        v-if="currentFacility"
        :current-facility="currentFacility"
      />
      <fki-footer is-active>
        <fki-footer-nav is-active />
      </fki-footer>
    </div>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKISubNav, FKIFooterNav } from '@/components/FKINav'
import { FKIContainer } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'
import { FKIContactFacility } from '@/components/FKIContact'

export default {
  name: 'ContactFacility',
  components: {
    'fki-sub-nav': FKISubNav,
    'fki-container': FKIContainer,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-facility': FKIContactFacility
  },
  props: {
    facility: {
      type: String,
      default: 'fotokem'
    }
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      siteKey: '6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3',
      currentFacility: null,
      page: { color: 'orange', body: null, slides: [] },
      items: [],
      facilities: {}
    }
  },
  watch: {
    $route: 'checkParams'
  },
  async created () {
    if (this.$route.path === '/contact') {
      this.$router
        .push({
          path: '/contact/fotokem'
        })
        .catch(() => {})
    }
    await this.getPage()
    await this.getContacts()
    this.getContact()
  },
  methods: {
    async checkParams () {
      await this.getContacts()
      if (this.$route.path === '/contact') {
        this.$router
          .push({
            path: '/contact/fotokem'
          })
          .catch(() => {})
      }
      this.getContact()
    },
    async getContacts () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/contacts')
        this.items = []
        this.facilities = result

        for (const key in result) {
          if (Object.hasOwnProperty.call(result, key)) {
            let center = null
            let marker = null
            switch (key) {
              case 'fotokem':
                center = { lat: 34.15759317353441, lng: -118.33171793253328 }
                break
              case 'atlanta':
                center = { lat: 33.77417036278668, lng: -84.38353601580474 }
                marker = { lat: 33.77417036278668, lng: -84.38353601580474 }
                break
              case 'new_orleans':
                center = { lat: 29.931693834461548, lng: -90.06993098646132 }
                marker = { lat: 29.931693834461548, lng: -90.06993098646132 }
                break
              case 'nonlinear-services':
                center = { lat: 34.09128891852948, lng: -118.34011782785599 }
                marker = { lat: 34.09128891852948, lng: -118.34011782785599 }
                break
              case 'santa_monica':
                center = { lat: 34.015802196119351, lng: -118.48643641579828 }
                marker = { lat: 34.015802196119351, lng: -118.48643641579828 }
                break
              default:
                break
            }
            this.items.push({
              slug: 'contact/' + result[key].slug,
              color: this.page.color,
              name: result[key].name

            })
            this.facilities[key].center = center
            this.facilities[key].marker = marker
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/page/s/contact')
        this.page = result
        this.page.forEach(element => {
          element.slides.forEach(item => {
            item.src = item.src.replace('image/', '')
          })
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    getContact () {
      this.currentFacility = this.facilities[this.$route.params.facility]
      console.log(this.currentFacility)
    }
  }
}
</script>
<style lang="scss" scoped>
  .fki-contact-page {
    flex-direction: column;
    bottom:0;
  }
  .fki-contact-wrapper {
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    height: 100vh;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
</style>
